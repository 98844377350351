import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { formatPostDate } from "../lib/formatPostDate";
import SmallText from "../styles/SmallText";

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div>
        <Post>
          <h1>{frontmatter.title}</h1>
          <SmallText>{formatPostDate(frontmatter.date)}</SmallText>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Post>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date
        path
        title
      }
    }
  }
`;

const Post = styled.article`
  h1 {
    margin: 0;
    color: ${({ theme }) => theme.primary};
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: none;
  }
`;
